<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: '16px',}">
    <a-row :gutter="16">
      <a-col :span="5">
        <a-row type="flex" align="middle" style="margin-bottom: 12px;">
          <a-col :span="20">
            <h5 class="font-semibold m-0">站点</h5>
          </a-col>
          <a-col :span="4" style="display: flex; align-items: center; justify-content: flex-end">
            <a-button icon="reload"
                      :websiteLoading="websiteLoading"
                      @click="loadWebsiteData" />
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-select style="width: 100%;"
                      v-model="activityWebsiteId"
                      :options="websiteOptions"
            />
          </a-col>
        </a-row>
        <a-row style="margin-top: 12px;margin-bottom: 12px;">
          <a-row type="flex" align="middle">
            <a-col :span="20">
              <h5 class="font-semibold m-0">栏目</h5>
            </a-col>
            <a-col :span="4" style="display: flex; align-items: center; justify-content: flex-end">
              <a-button icon="reload"
                        :websiteLoading="websiteLoading"
                        @click="loadWebsiteData" />
            </a-col>
          </a-row>
        </a-row>
        <a-spin :spinning="columnLoading">
          <div class="column-container">
            <a-tree :tree-data="columnOptions"
                    :replace-fields="{ title: 'name', key: 'id' }"
                    autoExpandParent
                    tree-default-expand-all
                    default-expand-all
                    :selectedKeys="[activityColumnId]"
                    @select="onColumnSelect"
            />
          </div>
        </a-spin>
      </a-col>
      <a-col :span="19">
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">{{ moduleName }}</h5>
          </a-col>
          <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
            <a-button type="primary" icon="plus" @click="toEdit({})">创建{{ moduleName }}</a-button>
          </a-col>
        </a-row>
        <a-table :loading="tableLoading" :columns="finalColumns" :data-source="datasource"
                 :rowKey="record => record.id">
          <template slot="cover" slot-scope="text">
            <a-avatar shape="square" :src="text[0] || ''" />
          </template>
          <template slot="article" slot-scope="_, record">
            <div class="article-title">
              <h6><span>[{{ record.highlight }}]</span>{{ record.title }}</h6>
              <p>{{ record.subtitle }}</p>
            </div>
          </template>
          <template slot="website" slot-scope="_, record">
            <div class="article-title">
              <h6>{{ record.websiteName }}</h6>
              <p>{{ record.websiteDomain }}</p>
            </div>
          </template>
          <template slot="time" slot-scope="text">
            <time-tag :time="text" />
          </template>
          <template slot="state" slot-scope="text">
            <a-tag :color="ENUM_STATE[text].tagColor">{{ ENUM_STATE[text].label }}</a-tag>
          </template>
          <template slot="action" slot-scope="row">
            <a-button type="link" size="small" :data-id="row.key" icon="eye" @click="toDetail(row)">
              查看
            </a-button>
            <a-button type="link" size="small" :data-id="row.key" icon="form" @click="toEdit(row)">
              编辑
            </a-button>
            <a-button type="link" size="small" class="danger-button" :data-id="row.key" icon="delete"
                      @click="doDelete(row, 'title')">
              删除
            </a-button>
          </template>
        </a-table>
      </a-col>
    </a-row>
    <ActDrawer ref="ActDrawerRef" @success="loadData" />
  </a-card>
</template>

<script>
import {query, remove} from "@/api/service/article";
import {query as websiteQuery} from "@/api/service/website";
import {ENUM_STATE} from "@/model/constant";
import ActDrawer from "@/views/article/ActDrawer.vue";
import {tableMixin} from "@/mixin/table";
import {columns} from "@/views/article/options";
import {isEmpty} from "lodash";
import {tree} from "@/api/service/column";
import TimeTag from "@/components/table/time-tag.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {TimeTag, ActDrawer},
  mixins: [tableMixin],
  data() {
    return {
      moduleName: '文章',
      columns,
      ENUM_STATE,
      websiteLoading: false,
      activityWebsiteId: '',
      columnLoading: false,
      columnData: [],
      activityColumnId: '',
      immediate: false
    }
  },
  computed: {
    ...mapGetters({
      websiteOptions: 'selection/websiteOptions',
      columnOptions: 'selection/columnOptions'
    })
  },
  methods: {
    ...mapActions({
      loadWebsiteOptions: 'selection/loadWebsiteOptions',
      loadColumnOptions: 'selection/loadColumnOptions'
    }),
    remove,
    query,
    toEdit(record) {
      this.$refs.ActDrawerRef.open({
        edit: true,
        record,
        websiteId: this.activityWebsiteId,
        columnId: this.activityColumnId,
      })
    },
    toDetail(record) {
      this.$refs.ActDrawerRef.open({
        edit: false,
        record
      })
    },
    async loadWebsiteData() {
      this.websiteLoading = true
      await this.loadWebsiteOptions()
      this.websiteLoading = false
      if(!isEmpty(this.websiteOptions)) {
        this.activityWebsiteId = this.websiteOptions[0].id
        this.loadColumnData()
      }
    },
    async loadColumnData() {
      this.columnLoading = true
      await this.loadColumnOptions({websiteId: this.activityWebsiteId})
      this.columnLoading = false
      if(!isEmpty(this.columnOptions)) {
        this.onColumnSelect([this.columnOptions[0].id])
      }
    },
    onColumnSelect(selectedKeys) {
      this.activityColumnId = selectedKeys[0]
      this.setProbe({
        field: 'column_id',
        value: this.activityColumnId,
        rule: 'EQ'
      })
    }
  },
  mounted() {
    this.loadWebsiteData()
  }
}

</script>
