<template>
  <a-drawer :visible="visible" @close="close" width="70%" :mask-closable="false"
            :get-container="false" :closable="false"
            :wrap-style="{ position: 'absolute' }"
            :header-style="{position: 'sticky', top: 0, zIndex: 100,}">
    <div slot="title" class="title-container">
      <a-page-header title="文章管理" :sub-title="edit ? '编辑' : '详情'" style="border: none;width: 100%;padding: 8px 0;">
        <template slot="extra">
          <a-button v-if="edit" type="primary"
                    :loading="formLoading" icon="check" @click="handleSave">保存</a-button>
          <a-button v-else type="primary" icon="form" @click="toEdit">编辑</a-button>
          <a-button icon="close" @click="close">取消</a-button>
        </template>
      </a-page-header>
    </div>
    <div>
      <a-form v-if="edit" :form="form" layout="vertical">
        <a-row>
          <a-col :span="24">
            <a-form-item>
              <a-input
                  v-decorator="['title', { rules: [{ required: true, message: '请输入文章标题!' }] }, ]"
                  placeholder="文章标题" size="large" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-item>
              <a-input-group compact>
                <a-input style="width: 30%"
                    v-decorator="['highlight', {}, ]" placeholder="高亮：推荐、重点…" />
                <a-input style="width: 70%"
                    v-decorator="['subtitle', {}, ]" placeholder="文章附标题" />
              </a-input-group>
            </a-form-item>
          </a-col>
        </a-row>
        <moc-editor ref="MocEditorRef" />
        <a-divider orientation="left">发布设置</a-divider>
        <a-form-item label="文章封面">
          <cover-uploader ref="CoverUploaderRef" />
        </a-form-item>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="所属站点">
              <a-select
                  v-decorator="['websiteId', { rules: [{ required: true, message: '请输入文章标题!' }] }, ]"
                  :options="websiteOptions"
                  disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="所属栏目">
              <a-tree-select
                  v-decorator="['columnId', { rules: [{ required: true, message: '请输入文章标题!' }] }, ]"
                  :tree-data="columnOptions"
                  :replace-fields="{ title: 'name', value: 'id', key: 'id' }"
                  :showCheckedStrategy="TreeSelect.SHOW_ALL"
                  disabled />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <template v-else>
        <a-descriptions layout="vertical" bordered>
          <a-descriptions-item v-for="field in detailFields" :key="field.dataIndex" :label="field.title">
            {{ detailData[field.dataIndex] }}
          </a-descriptions-item>
        </a-descriptions>
        <div class="detail-bottom"
            :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
        >
          创建时间：{{ detailData.createdTime | relativeTime }} 更新时间：{{ detailData.updatedTime | relativeTime }}
        </div>
      </template>
    </div>
  </a-drawer>
</template>

<script>
import {detail, save} from "@/api/service/article";
import {detail as websiteDetail} from "@/api/service/website";
import {detail as columnDetail} from "@/api/service/column";
import {isEmpty} from "lodash";
import {formFieldHandler} from "@/utils/form";
import {columns} from "@/views/article/options";
import {detailFieldHandler} from "@/utils/detail";
import MocEditor from "@/components/editor/MocEditor.vue";
import CoverUploader from "@/components/CoverUploader/index.vue";
import {TreeSelect} from "ant-design-vue";
import {mapActions, mapGetters} from "vuex";
import {ENUM_STATE} from "../../model/constant";

export default {
  computed: {
    ...mapGetters({
      websiteOptions: 'selection/websiteOptions',
      columnOptions: 'selection/columnOptions',
    }),
    TreeSelect() {
      return TreeSelect
    }
  },
  components: {MocEditor, CoverUploader},
  data() {
    return {
      visible: false,
      edit: false,
      formFields: formFieldHandler(columns),
      detailFields: detailFieldHandler(columns),
      form: null,
      formLoading: false,
      detailData: {},
    }
  },
  methods: {
    ...mapActions({
      loadWebsiteOptions: 'selection/loadWebsiteOptions',
      loadColumnOptions: 'selection/loadColumnOptions',
    }),
    open({edit, record, websiteId, columnId}) {
      this.visible = true
      this.form = this.$form.createForm(this, {name: 'website_form'})
      this.edit = edit
      if(!isEmpty(record)) {
        this.detailData = record
      }
      if (edit && !isEmpty(this.detailData)) {
        this.recoveryData()
      }
      this.recoveryWebsiteData(websiteId, columnId)
    },
    close() {
      this.form.resetFields()
      Object.assign(this.$data, this.$options.data())
    },
    toEdit() {
      this.edit = true
      this.recoveryData()
    },
    async recoveryWebsiteData(websiteId, columnId) {
      await this.loadWebsiteOptions()
      if(!isEmpty(this.websiteOptions)) {
        const target = this.websiteOptions.find(item => item.id === this.detailData.websiteId || item.id === websiteId)
        this.form.setFieldsValue({
          websiteId: target.id,
        })
        this.recoveryColumnData(target.id, columnId)
      }
    },
    async recoveryColumnData(websiteId, columnId) {
      await this.loadColumnOptions({websiteId})
      this.form.setFieldsValue({
        columnId,
      })
    },
    recoveryData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          title: this.detailData.title,
          subtitle: this.detailData.subtitle,
          highlight: this.detailData.highlight,
        })
        this.$refs.MocEditorRef.setData(this.detailData.content)
        this.$refs.CoverUploaderRef.setData(this.detailData.coverImages)
      })
    },
    async reloadDetail(result) {
      const { success, data } = await detail({id: this.detailData.id || result.id})
      if(success) {
        this.detailData = data
      }
    },
    handleSave() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.doSave(values)
        }
      })
    },
    async doSave(values) {
      const website = await websiteDetail({id: values.websiteId})
      const column = await columnDetail({id: values.columnId})
      const params = {
        ...values,
        websiteName: website.name,
        websiteDomain: website.domain,
        columnName: column.name,
        content: this.$refs.MocEditorRef.getData(),
        coverImages: this.$refs.CoverUploaderRef.getData(),
        state: ENUM_STATE.RELEASED.value
      }
      if (this.detailData.id) {
        params.id = this.detailData.id
      }
      const {success, data} = await save(params)
      if (success) {
        this.$notification.success({
          message: '操作成功',
          duration: 2,
          onClose: () => {
            this.reloadDetail(data)
            this.edit = false
            this.$emit('success')
          }
        });
      }
    }
  }
}
</script>

<style>
.detail-bottom{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
</style>
