export function formFieldHandler(columns, hasAction = true) {
  return columns.filter(item => item.showPos.form);
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    console.log(reader.result);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
