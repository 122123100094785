<template>
  <a-tooltip>
    <template #title>{{ time | datetimeFormat }}</template>
    <a-tag color="blue">{{ time | relativeTime }}</a-tag>
  </a-tooltip>
</template>

<script>
export default {
  props: {
    time: String,
  }
}
</script>

