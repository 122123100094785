import {columnsFilter} from "@/utils/table";
import {isEmpty} from "lodash";

export const tableMixin = {
  data() {
    return {
      moduleName: '',
      tableLoading: false,
      columns: [],
      datasource: [],
      requestType: 'normal',
      immediate: true,
      seeker: {
        probes: [],
        sorters: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
        },
      },
      treeParams: {}
    }
  },
  computed: {
    finalColumns() {
      return columnsFilter(this.columns)
    }
  },
  methods: {
    query() {},
    tree() {},
    remove() {},
    setProbe(probe, immediate = true) {
      let targetIndex = this.seeker.probes.findIndex(item => item.field === probe.field)
      if(targetIndex === -1) {
        this.seeker.probes.push(probe)
      } else {
        this.seeker.probes[targetIndex] = {...probe}
      }
      if(immediate) {
        this.loadData()
      }
    },
    async loadData() {
      this.tableLoading = true
      if(this.requestType === 'normal') {
        const { success, data: { content } } = await this.query(this.seeker)
        if(success) {
          this.datasource = content
        }
      } else {
        const { success, data: { nodes } } = await this.tree(this.treeParams)
        if(success) {
          this.datasource = nodes
        }
      }
      this.tableLoading = false
    },
    doDelete(record, nameKey = 'name') {
      this.$confirm({
        title: `确认删除?`,
        content: `确认删除${this.moduleName}：${record[nameKey]}`,
        okType: 'danger',
        onOk: async () => {
          return new Promise((resolve, reject) => {
            this.remove(record).then(({ success }) => {
              if(success) {
                this.loadData()
                this.$notification.success({
                  message: '操作成功',
                  duration: 2,
                  onClose: () => {
                    resolve()
                  }
                });
              }
            })
          })
        },
      });
    },
  },
  mounted() {
    if(this.immediate) {
      this.loadData()
    }
  }
}
