// {
//   "id": "3213166348284723201",
//   "creatorId": "19",
//   "updaterId": "20",
//   "createdTime": "2010-02-13 08:01:52",
//   "updatedTime": "1997-03-25 17:54:52",
//   "title": "资象边",
//   "subtitle": "把进关称",
//   "highlight": "esse",
//   "introduction": "ex id",
//   "coverImages": [
//   "http://dummyimage.com/400x400"
// ],
//   "content": "qui et quis proident",
//   "linkUrl": "http://wmegz.hm/rmhbopgxd",
//   "columnId": "18",
//   "columnName": "入高但般子场变",
//   "websiteId": "83",
//   "websiteName": "千却才始联商",
//   "websiteDomain": "k.nup@qq.com",
//   "sort": 68,
//   "top": 1,
//   "source": "Lorem",
//   "display": 1,
//   "state": "UNKNOWN",
//   "extendData": {}
// }

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    showPos: {
      table: false,
      form: false,
    }
  },
  {
    title: '封面',
    dataIndex: 'coverImages',
    align: 'center',
    scopedSlots: {customRender: 'cover'},
    showPos: {
      table: true,
      form: true,
      detail: true,
    },
    formConfig: {
      rules: []
    },
    dataConfig: {}
  },
  {
    title: '文章标题',
    dataIndex: 'title',
    scopedSlots: {customRender: 'article'},
    showPos: {
      table: true,
      form: true,
      detail: true,
    },
    formConfig: {
      rules: [{ required: true, message: '请输入站点名称!' }]
    },
    dataConfig: {}
  },
  // {
  //   title: '所属栏目',
  //   dataIndex: 'columnName',
  //   showPos: {
  //     table: true,
  //     form: true,
  //     detail: true,
  //   },
  //   formConfig: {
  //     rules: [{ required: true, message: '请输入站点域名!' }]
  //   },
  //   dataConfig: {}
  // },
  // {
  //   title: '所属站点',
  //   dataIndex: 'website',
  //   scopedSlots: {customRender: 'website'},
  //   showPos: {
  //     table: true,
  //     form: true,
  //     detail: true,
  //   },
  //   formConfig: {
  //     comp: 'textarea',
  //     rules: []
  //   },
  //   dataConfig: {}
  // },
  {
    title: '创建时间',
    dataIndex: 'createdTime',
    scopedSlots: {customRender: 'time'},
    showPos: {
      table: true,
      form: false,
    }
  },
  {
    title: '更新时间',
    dataIndex: 'updatedTime',
    scopedSlots: {customRender: 'time'},
    showPos: {
      table: true,
      form: false,
    }
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: {customRender: 'state'},
    showPos: {
      table: true,
      form: false,
    }
  },
]
