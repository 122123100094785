<template>
  <div class="editor-container">
    <quill-editor  ref="myQuillEditor"
                   v-model="content"
                   :options="editorOption"
                   class="my-quill-editor" />
    <div style="visibility: hidden; height: 0">
      <a-upload
          name="file"
          list-type="picture-card"
          :file-list="fileList"
          class="avatar-uploader"
          :show-upload-list="false"
          :action="actionURL"
          :headers="headers"
          :before-upload="beforeUpload"
          @change="handleChange"
      >
        <a-button class="uploader-btn" ref="uploader"> Click to Upload </a-button>
      </a-upload>
    </div>
  </div>
</template>

<script>
import {isEmpty} from "lodash";
import {OSS} from "../../api/service/oss";
import {mapGetters} from "vuex";
import {getBase64} from "@/utils/form";

export default {
  props: {
    value: String
  },
  data() {
    return {
      actionURL: OSS.upload,
      loading: false,
      fileList: [],

      content: '',
      editorOption: {
        // 占位配置
        placeholder: '请输入内容...',
        height: '300px',
        modules: {
          // 配置工具栏
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              ['blockquote', 'code-block'], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              ['link', 'image', 'video'], // 链接、图片、视频
              [{ align: [] }], // 添加居中按钮
              [{ color: [] }], // 文字颜色按钮
            ],
            handlers: {
              // 重写点击组件上的图片按钮要执行的代码
              'image': function (value) {
                document.querySelector('.editor-container .uploader-btn').click()
              }
            }
          },
          // 更改插入的图片大小
          // imageResize: {
          //   displayStyles: {
          //     backgroundColor: "black",
          //     border: "none",
          //     color: "white",
          //   },
          //   modules: [
          //     'Resize',
          //     'DisplaySize',
          //     'Toolbar'
          //   ],
          // },
        }
      },
    }
  },
  // watch: {
  //   content() {
  //     this.$emit('input', this.content)
  //   }
  // },
  computed: {
    ...mapGetters({
      token: 'auth/token'
    }),
    headers() {
      const headers = {}
      if(!isEmpty(this.token)) {
        headers['Authorization'] = `Bearer ${this.token}`
      }
      return headers
    }
  },
  methods: {
    // handleChange(info) {
    //   console.log(info);
    //   if (info.file.status === 'uploading') {
    //     this.loading = true;
    //     return;
    //   }
    //   if (info.file.status === 'done') {
    //     // Get this url from response in real world.
    //     getBase64(info.file.originFileObj, imageUrl => {
    //
    //       console.log(imageUrl);
    //
    //       this.imageUrl = imageUrl;
    //       this.loading = false;
    //
    //       // 获取文本编辑器
    //       const quill=this.$refs.myQuillEditor.quill
    //       // 获取光标位置
    //       const pos=quill.getSelection().index
    //       // 插入图片到光标位置
    //       quill.insertEmbed(pos,'image', imageUrl)
    //     });
    //   }
    // },
    handleChange({ fileList, file }) {
      if (file.status === 'done') {
        // 获取文本编辑器
        const quill=this.$refs.myQuillEditor.quill
        // 获取光标位置
        const pos=quill.getSelection().index
        // 插入图片到光标位置
        quill.insertEmbed(pos,'image', file.response.data)
      }
      this.fileList = fileList;
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },

    getData() {
      return this.content
    },
    setData(content) {
      this.content = content
    }
  },
  // created() {
  //   if(!isEmpty(this.value) && isEmpty(this.content)) {
  //     this.content = this.value
  //   }
  // }
}
</script>

<style lang="scss">
.editor-container{
  min-height: 300px;
}
/* 编辑器高度及背景色 */
.editor-container ::v-deep .quill-editor {
  //min-height: 300px;
}
.editor-container ::v-deep .ql-editor {
  background-color: #fff;
}
.ql-container{
  min-height: 360px;
}
</style>
