export const ENUM_STATE = {
  UNKNOWN: {
    tagColor: '#999',
    label: '未发布',
    value: 0,
  },
  RELEASED: {
    tagColor: 'green',
    label: '已发布',
    value: 1,
  },
  TO_BE_RELEASED: {
    tagColor: 'orange',
    label: '待发布',
    value: 2,
  },
  REVOKE: {
    tagColor: 'red',
    label: '已撤销',
    value: 3,
  },
}
