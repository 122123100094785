<template>
  <div class="clearfix">
    <a-upload
        :action="actionURL"
        :headers="headers"
        list-type="picture-card"
        :file-list="fileList"
        @preview="handlePreview"
        @change="handleChange"
    >
      <div v-if="fileList.length < 8">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          上传图片
        </div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import {getBase64} from "@/utils/form";
import {OSS} from "@/api/service/oss";
import {mapGetters} from "vuex";
import {isEmpty} from "lodash";

export default {
  name: 'CoverUploader',
  data() {
    return {
      actionURL: OSS.upload,
      previewVisible: false,
      previewImage: '',
      fileList: [],
    };
  },
  computed: {
    ...mapGetters({
      token: 'auth/token'
    }),
    headers() {
      const headers = {}
      if(!isEmpty(this.token)) {
        headers['Authorization'] = `Bearer ${this.token}`
      }
      return headers
    }
  },
  methods: {
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    getData() {
      return this.fileList.map(file => file.response.data)
    },
    setData(fileList) {
      this.fileList = fileList.map((url, index) => ({
        uid: 'index',
        name: 'image.png',
        status: 'done',
        url
      }))
    }
  },
};
</script>

<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
